export function unsubscribe() {
  return function (constructor: any) {
    const originalDestroy = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      for (let prop in this) {
        const property = this[prop];
        if (property && typeof property.unsubscribe === 'function') {
          property.unsubscribe();
        }
      }
      originalDestroy.apply(this, arguments);
    };
  };
}
